@import "../settings";
// Vars
$primary-color: map_get($foundation-palette, primary);
$secondary-color: map_get($foundation-palette, secondary);
$success-color: map_get($foundation-palette, success);
$warning-color: map_get($foundation-palette, warning);
$alert-color: map_get($foundation-palette, alert);

$global-shadow: 0 0 18px rgba($black, .2);

.overflow-hidden {
  overflow: hidden;
}

// Global style
.section-title {
  font-size: 22px;
  margin-bottom: 10px;
  font-family: $header-font-family;

  @include breakpoint(medium) {
    font-size: 36px;
    margin-bottom: 20px;
  }

  &.small {
    font-size: 19px;

    @include breakpoint(medium) {
      font-size: 24px;
    }
  }
}

.section-subtitle {
  color: $dark-gray;
  font-size: 19px;
  margin-bottom: 10px;

  @include breakpoint(medium) {
    font-size: 24px;
    margin-bottom: 20px;
  }
}

.space {
  height: 20px;

  @include breakpoint(medium) {
    height: 30px;
  }

  &.small {
    height: 10px;
  }

  &.medium {
    height: 30px;

    @include breakpoint(medium) {
      height: 50px;
    }
  }

  &.large {
    height: 50px;

    @include breakpoint(medium) {
      height: 70px;
    }
  }
}

.small-list {
  list-style-type: none;

  @include breakpoint(medium) {
    font-size: 20px;
  }

  li {
    position: relative;
    padding-left: 30px;
    margin-bottom: 5px;

    .icon {
      width: 18px;
      height: 18px;
      fill: $primary-color;
      position: absolute;
      top: 4px;
      left: 0;
      transform: rotate(-90deg);

      @include breakpoint(medium) {
        top: 7px;
      }
    }
  }
}

.radius {
  border-radius: $global-radius;
}

.photo-quote {
  .quotes {
    fill: $medium-gray;
    margin-top: 30px;
  }
}

.photo-quote-img {
  margin-bottom: $global-margin;
}

.photo-quote-text {
  border-radius: $global-radius;
  box-shadow: $global-shadow;
  padding: 15px;
  background: $white;
  font-style: italic;

  @include breakpoint(medium) {
    padding: 30px 30px;
    margin-top: -70px;
  }
}

.read-more {
  overflow: hidden;
}

.read-more-link {
  font-style: normal;
  position: relative;
  padding-right: 25px;

  .icon {
    position: absolute;
    top: 4px;
    right: 0;
    fill: $primary-color;
  }
}

.read-less-link {
  @extend .read-more-link;

  .icon {
    transform: rotate(180deg);
  }
}

.list-numbered {
  margin: 0;
  list-style-type: none;


  li {
    margin-bottom: $global-margin;
    position: relative;
    counter-increment: my-counter;
    padding-left: 35px;

    &:before {
      content: counter(my-counter);
      margin-right: 15px;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      background-image: linear-gradient(to right bottom, #4DC1FF, #0074B3);
      color: $white;
      position: absolute;
      top: 2px;
      left: 0;
      font-size: 14px;
    }

    //@include breakpoint(medium) {
    //  &:nth-child(4n+2) {
    //    margin-left: 34px;
    //  }
    //  &:nth-child(4n+3) {
    //    margin-left: 68px;
    //  }
    //  &:nth-child(4n+4) {
    //    margin-left: 102px;
    //  }
    //}
  }
}

.quote {
  border-radius: $global-radius;
  box-shadow: $global-shadow;
  padding: 15px;

  @include breakpoint(medium) {
    padding: 30px;
  }

  > :last-child {
    margin-bottom: 0;
  }
}

.button {
  font-weight: 500;
}

.sale-text {
  font-size: 24px;
  font-weight: bold;
}

.grid-container.expanded {
  max-width: 100%;
}

.slider-nav {
  display: flex;
  align-items: center;
  justify-content: center;

}

.slider-nav-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  border-radius: 100px;
  box-shadow: $global-shadow;
  padding: 10px;
  background: rgba($white, .75);
}

.slider-button-prev {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:focus {
    outline: 0;
  }

  .icon {
    fill: $primary-color;
    transform: rotate(90deg) scale(1.5);
  }
}

.slider-button-next {
  @extend .slider-button-prev;

  .icon {
    transform: rotate(-90deg) scale(1.5);
  }
}

.testimonial-cont {
  background: url("../img/wall-2.jpg") repeat;

  @include breakpoint(medium down) {
    padding: 0 20px;
  }
}

.slider-pagination {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.slider-pagination-bullet {
  margin: 7px;
  width: 12px;
  height: 12px;
  background: $medium-gray;
  border-radius: 100%;
  cursor: pointer;
  transition: background-color ease .2s;

  &:hover {
    background: $dark-gray;
  }

  &:focus {
    outline: 0;
  }
}

.slider-pagination-bullet-active {
  background: $primary-color;
}

.testimonial-slider-card {
  margin: 20px 0;
  background: $white;
  border-radius: $global-padding;
  box-shadow: $global-shadow;
  padding: 20px;
  transform: scale(0.85);
  opacity: .5;
  transition: opacity ease .2s, transform ease .2s;

  @include breakpoint(medium) {
    padding: 35px;
  }

  > :last-child {
    margin-bottom: 0;
  }
}

.swiper-slide-active .testimonial-slider-card {
  transform: scale(1);
  opacity: 1;
}

.swiper-wrapper {
  align-items: center;
}

a[data-fancybox]:focus {
  outline: 0;
}

.info-block-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.info-block-card-img {
  margin-bottom: 20px;
}

.info-block-card-count {
  font-family: $header-font-family;
  font-size: 32px;
  line-height: 1;
  margin-bottom: 12px;
  font-weight: bold;
}

.info-block-card-text {
  font-size: 18px;
  color: $dark-gray;

  @include breakpoint(medium) {
    font-size: 20px;
  }
}

.linear-list {
  margin: 0;
  list-style-type: none;

  li {
    padding: 0 0 12px 40px;
    margin-bottom: 12px;
    position: relative;

    .icon {
      position: absolute;
      top: 4px;
      left: 0;

      &.done_all {
        fill: $success-color;
      }

      &.close {
        fill: $alert-color;
      }
    }

    &:after {
      content: "";
      width: 120px;
      height: 0;
      border-top: 1px solid $medium-gray;
      position: absolute;
      left: 40px;
      bottom: 0;
    }

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;

      &:after {
        display: none;
      }
    }

  }
}

.icon-list {
  list-style-type: none;
  margin: 0;


  li {
    display: flex;
    align-items: center;

    @include breakpoint(medium) {
      padding-bottom: 10px;
    }
  }
}

.icon-list-img {
  margin: 0 30px $global-margin 0;
}

.info-grid {
  display: flex;
  flex-wrap: wrap;
  background: $black;

  @include breakpoint(medium) {
    flex-wrap: nowrap;
  }
}

.info-cell {
  height: 160px;
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  @include breakpoint(large) {
    height: 240px;
  }
}

.info-cell-img {
  display: block;
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: .5;
}

.info-cell-text {
  color: $white;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  position: relative;
  padding: 0 20px;

  @include breakpoint(large) {
    font-size: 24px;
  }
}

.unique {
  background: $light-gray;
}

.info-block-card-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.info-block-card-2-img {
  display: block;
  margin: 0 auto;
}

.info-block-card-2-text {
  padding-top: 20px;
  margin-bottom: 30px;
}

.text-block {
  > :last-child {
    margin-bottom: 0;
  }
}

.lesson-card {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 60%;
  overflow: hidden;
  background: $black;
  border-radius: $global-radius;
  margin-bottom: 20px;
}

.lesson-card-img {
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: .45;
}

.lesson-card-text {
  color: $white;
  text-align: center;
  padding: 20px 30px;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  font-size: 19px;

  @include breakpoint(medium) {
    font-size: 24px;
  }
}

.alert-color {
  color: $alert-color;
}

.accordion {
  box-shadow: 0 0 25px rgba($black, .12);

  .accordion-item:not(:last-child) {
    border-bottom: 1px solid #d4d4d4;

    &.is-active {
      border-bottom-color: #f3f3f3;
    }
  }
}

.accordion-item:first-child > :first-child,
.accordion-item:last-child > :last-child {
  border-radius: 0 !important;
}

.accordion-item {
  position: relative;

  .accordion-title {
    position: relative;
    color: $black;
    transition: color ease .3s;

    &:hover {
      color: $primary-color;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 5px;
      height: 100%;
      background: #d4d4d4;
      transition: background-color ease .3s;
    }
  }

  &.is-active {
    .accordion-title {
      color: darken($primary-color, 10);


      &:before {
        background: $primary-color;
      }
    }
  }
}

.accordion-content {
  > :last-child {
    margin-bottom: 0;
  }
}

.reveal {
  &:focus {
    outline: 0;
  }
}

.pay-block {
  position: relative;
  overflow: hidden;
  color: $white;

  .grid-container {
    position: relative;
  }
}

.pay-block-bg {
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pay-subtitle {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px;
}

.pay-or {
  margin: 10px 0;
  font-size: 22px;
}

.social-list {
  list-style-type: none;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-social {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#copyrights {
  font-size: 13px;
  background: $black;
  color: $white;
  padding: 5px 0;
}