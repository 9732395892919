@import "helpers";

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(720deg);
  }
}

#preloder {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: $white;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;

  .spiner {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border-top: 5px solid $primary-color;
    border-right: 5px solid lighten($primary-color, 30);
    border-bottom: 5px solid lighten($primary-color, 30);
    border-left: 5px solid lighten($primary-color, 30);
    animation: spin ease-in-out 1.4s infinite;
  }
}

#hero {
  background-image: linear-gradient(to right bottom, #4DC1FF, #0074B3);
  color: $white;
  padding: 10px 0;
  margin-bottom: 50px;

  @include breakpoint(medium) {
    padding: 30px 0;
    margin-bottom: 70px;
  }
}

#hero-nav {
  a {
    color: $white;
  }

  &.fixed {
    padding: 10px 15px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: $primary-color;
    border-bottom: 1px solid rgba($white, .4);
    z-index: 10;
    transform: translate(0, -100%)
  }

  &.active {
    transform: translate(0, 0);
    transition: transform ease .2s;
  }
}

.off-canvas{
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mobile-header-open{
  color: $white;
}

.hero-text {
  text-align: center;
  font-size: 19px;
  line-height: 1.3;
  margin: 20px 0;

  @include breakpoint(medium) {
    font-size: 26px;
    margin: 30px 0 40px;
  }
}

.warranty {
  background: $light-gray;
}

.warranty-img {
  margin-bottom: 30px;
}

.warranty-text {
  margin-bottom: 30px;

  p {
    @include breakpoint(medium) {
      font-size: 18px;
    }
  }
}